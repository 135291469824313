<template>
  <mj-roadmap/>
</template>

<script>
import Roadmap from "@/components/roadmap/Roadmap_components.vue";

export default {
  components: {
    mjRoadmap: Roadmap
  }
}
</script>
